import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import VueI18n from "vue-i18n";

import App from "./App.vue";
import router from "./router";

// Import the Auth0 configuration
import { domain, clientId, audience } from "../auth_config.js";

// Import the plugin here
import { Auth0Plugin } from "./auth";

// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain,
  clientId,
  audience,
  onRedirectCallback: (appState) => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  },
});

Vue.use(VueAxios, axios);

Vue.use(VueI18n);
const messages = {
  fr: require("./i18n/fr.json"),
  en: require("./i18n/en.json"),
};
const i18n = new VueI18n({
  locale: localStorage.getItem("language") || "en",
  fallbackLocale: "en",
  messages,
});

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
