<template>
  <div id="spotting">
    <div id="tab" class="cardback">
      <button
        id="back-button"
        class="n-btn-color-secondary"
        @click="returnToCode()"
      >
        Code
      </button>
      <span id="tablinks">
        <button :class="{ active: show_stats }" @click="show_stats = true">
          {{ $t("spotting.stats") }}
        </button>
        <button :class="{ active: !show_stats }" @click="show_stats = false">
          {{ $t("spotting.events") }}
        </button>
      </span>
      <div id="tabcontent" class="cardfront">
        <Statistics
          v-if="show_stats"
          :entries="entries"
          @change-value="addEvent"
          :closed="closed"
          :home="home"
          :away="away"
          :code="code"
        />
        <EventList
          v-else
          :events="events"
          :closed="closed"
          :clock="clock"
          @delete-event="removeEvent"
          @reset-all="resetEvent"
        />
      </div>
    </div>
    <div id="event-closed" class="cardback" v-if="closed">
      <h1>{{ $t("spotting.closed") }}</h1>
    </div>
    <div v-if="clock && !closed" id="time" class="cardback">
      <div class="cardfront">
        <div id="clock" @click.self="closeEditTimer()">
          <h2
            v-bind:class="{
              extratime:
                increment === 1
                  ? seconds >=
                    (part % 2 === 0 ? 2 : 1) *
                      (part >= 5 ? this.overtime : this.duration)
                  : seconds <= 0,
            }"
            @click="editTimer()"
            v-if="!timerStatus"
          >
            {{ time }}
          </h2>
          <input v-else v-model="editedTimer" :placeholder="time" />
          <h3 @click="closeEditTimer()">
            {{
              `${part >= 5 ? part - 4 : part}${
                part >= 5
                  ? "OT"
                  : time_format === "HT"
                  ? $t("spotting.half")
                  : "QT"
              }`
            }}
          </h3>
        </div>
        <span @click="closeEditTimer()">
          <button
            class="start n-btn-color-success"
            v-if="timer === null"
            @click="start()"
            :disabled="this.seconds === 0 && this.increment === -1"
          >
            <span class="material-icons">play_arrow</span>
          </button>
          <button class="start n-btn-color-danger" v-else @click="pause()">
            <span class="material-icons">pause</span>
          </button>
          <button class="n-btn-color-warning" @click="reset()">
            <span class="material-icons">stop</span>
          </button>
          <button class="n-btn-color-primary" @click="changePart()">
            {{
              `${part >= 5 ? part - 4 : part}${
                part >= 5
                  ? "OT"
                  : time_format === "HT"
                  ? $t("spotting.half")
                  : "QT"
              }`
            }}
          </button>
        </span>
      </div>
    </div>
    <Modal v-if="modal.status === true" @close="modal.status = false">
      <template v-if="modal.type === 'part-selection'">
        <div class="modal-elements">
          <span>
            <button class="n-btn-color-primary" @click="updatePart(1)">
              1{{ time_format === "HT" ? $t("spotting.half") : "QT" }}
            </button>
            <button class="n-btn-color-primary" @click="updatePart(2)">
              2{{ time_format === "HT" ? $t("spotting.half") : "QT" }}
            </button>
          </span>
          <span>
            <button
              class="n-btn-color-primary"
              v-if="time_format === 'QT'"
              @click="updatePart(3)"
            >
              3QT
            </button>
            <button
              class="n-btn-color-primary"
              v-if="time_format === 'QT'"
              @click="updatePart(4)"
            >
              4QT
            </button>
          </span>
          <span>
            <button
              class="n-btn-color-primary"
              v-if="!isNaN(overtime)"
              @click="updatePart(5)"
            >
              1OT
            </button>
            <button
              class="n-btn-color-primary"
              v-if="!isNaN(overtime)"
              @click="updatePart(6)"
            >
              2OT
            </button>
          </span>
        </div>
      </template>
      <template v-if="modal.type === 'confirm-action'">
        <div class="modal-elements">
          <h3 v-if="modal.action === 'go-code'">
            {{ $t("spotting.go-code") }}
          </h3>
          <span>
            <button
              class="n-btn-color-success"
              @click="executeOnConfirm(modal.action)"
            >
              <span class="material-icons">check</span>
            </button>
            <button class="n-btn-color-danger" @click="modal.status = false">
              <span class="material-icons">close</span>
            </button>
          </span>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { read, update } from "@/api/crud";
import { statsOfStatsModel, valueOfStatInEvent } from "@/api/specific";
import { clock } from "@/api/clock";

import Statistics from "@/components/Statistics";
import EventList from "@/components/EventList";
import Modal from "@/components/Modal";

export default {
  name: "Home",
  components: {
    Statistics,
    EventList,
    Modal,
  },
  data() {
    return {
      code: null,
      home: "Home",
      away: "Away",
      closed: false,
      editedTimer: "",
      timerStatus: false,
      modal: {
        status: false,
        type: "part-selection",
      },
      overtime: null,
      clock: false,
      time_format: "HT",
      part: 1,
      duration: 20,
      show_stats: true,
      seconds: 0,
      timer: null,
      entries: [],
      events: [],
      increment: 1,
      retry: null,
      status_timer: false,
      event: null,
    };
  },
  async mounted() {
    this.code = localStorage.code;
    if (localStorage.events !== "undefined") {
      this.events = JSON.parse(localStorage.events);
    }
    try {
      this.event = await read("spotted_events", this.code, this.code);
    } catch (error) {
      console.error(error);
      this.$router.push("code");
    }
    try {
      this.home = this.event.home;
      this.away = this.event.away;
      this.closed =
        this.event.status === -1 ? (this.closed = true) : (this.closed = false);
      const stats_model = await read(
        "stats_models",
        this.code,
        this.event.fk_stats_model,
        60
      );
      if (stats_model.chrono === null) {
        this.clock = false;
      } else {
        const chrono = stats_model.chrono;
        this.clock = true;
        this.duration = chrono.regulation_time * 60;
        this.time_format = chrono.part === 2 ? "HT" : "QT";
        this.increment = chrono.increasing ? 1 : -1;
        this.seconds =
          localStorage.seconds !== "undefined"
            ? parseInt(localStorage.seconds, 10)
            : chrono.increasing
            ? 0
            : this.duration;
        this.overtime = chrono.overtime * 60;
      }
      const stats = await statsOfStatsModel(this.code, stats_model.id, 60);
      let newEntries = [];
      for (let i = 0; i < stats.length; i++) {
        const element = stats[i];
        const values = await valueOfStatInEvent(
          this.code,
          element.id,
          this.code,
          60
        );
        const newElement = {
          ...element,
          home: values.home,
          away: values.away,
          stat_values: values.id,
        };
        newEntries.push(newElement);
        newEntries.sort((a, b) => a.id - b.id);
      }
      this.entries = newEntries;
      if (localStorage.part !== "undefined") {
        this.part = localStorage.part;
      }
      if (localStorage.timer === "true") {
        await this.pauseClock();
        await this.startClock();
        this.status_timer = JSON.parse(localStorage.events);
      }
    } catch (error) {
      console.error(error);
    }
  },
  computed: {
    time() {
      const minutes = this.seconds !== 0 ? Math.floor(this.seconds / 60) : 0;
      const seconds = this.seconds % 60;
      return this.seconds >= 0
        ? `${minutes < 10 ? `0${minutes}` : `${minutes}`}:${
            seconds < 10 ? `0${seconds}` : `${seconds}`
          }`
        : `${
            -1 * minutes < 10 ? `0${-1 * minutes - 1}` : `${-1 * minutes - 1}`
          }:${-1 * seconds < 10 ? `0${-1 * seconds}` : `${-1 * seconds}`}`;
    },
  },
  methods: {
    async addEvent(payload) {
      this.events.unshift({
        id: this.events.length,
        timestamp: this.clock ? this.seconds : null,
        stat: payload.stat,
        side: payload.side === "home" ? this.home : this.away,
      });
      localStorage.events = JSON.stringify(this.events);
      if (
        new Date(this.event.datetime) < new Date() &&
        this.events.length === 1
      ) {
        await update("spotted_events", this.code, this.code, { status: 1 });
      }
    },
    async removeEvent(payload) {
      try {
        const stat = payload.event.stat;
        payload.event.side === this.home ? (stat.home -= 1) : (stat.away -= 1);
        await update(
          "stat_values",
          this.code,
          stat.stat_values,
          payload.event.side === this.home
            ? {
                home: stat.home,
                stat_key: stat.stat_key,
                event: this.code,
              }
            : {
                away: stat.away,
                stat_key: stat.stat_key,
                event: this.code,
              },
          60
        );
        this.events.splice(this.events.indexOf(payload.event), 1);
      } catch (error) {
        console.error(error);
      }
    },
    async resetAll() {
      try {
        this.modal.status = false;
        this.resetClock(true);
        await update("spotted_events", this.code, this.code, { status: 0 });
        this.entries.forEach(async (element) => {
          await update("stat_values", this.code, element.stat_values, {
            home: 0,
            away: 0,
            stat_key: element.stat_key,
            event: this.code,
          });
          element.home = 0;
          element.away = 0;
        });
        this.events = [];
        this.part = 1;
        localStorage.events = "undefined";
        localStorage.seconds = "undefined";
        localStorage.timer = "undefined";
        localStorage.part = "undefined";
      } catch (error) {
        console.error(error);
      }
    },
    resetEvent(payload) {
      this.modal = payload;
    },
    start() {
      localStorage.timer = "true";
      this.startClock();
    },
    async startClock() {
      clearInterval(this.retry);
      this.timer = setInterval(() => {
        this.seconds += this.increment;
        localStorage.seconds = this.seconds;
      }, 1000);
      const date = new Date();
      try {
        await clock("start", this.code, {
          date: date,
          seconds: this.seconds,
          part: this.part,
          event: this.code,
        });
      } catch (error) {
        console.error(error);
        this.retry = setInterval(async () => {
          try {
            await clock("start", this.code, {
              date: date,
              seconds: this.seconds,
              part: this.part,
              event: this.code,
            });
            clearInterval(this.retry);
          } catch (error) {
            console.error(error);
          }
        }, 1000);
      }
    },
    pause() {
      localStorage.timer = "false";
      this.pauseClock();
    },
    async pauseClock() {
      clearInterval(this.timer);
      clearInterval(this.retry);
      const date = new Date();
      try {
        await clock("pause", this.code, {
          date: date,
          seconds: this.seconds,
          part: this.part,
          event: this.code,
        });
      } catch (error) {
        console.error(error);
        this.retry = setInterval(async () => {
          try {
            await clock("pause", this.code, {
              date: date,
              seconds: this.seconds,
              part: this.part,
              event: this.code,
            });
            clearInterval(this.retry);
          } catch (error) {
            console.error(error);
          }
        }, 1000);
      }
      this.timer = null;
    },
    reset() {
      localStorage.timer = "false";
      localStorage.seconds = "0";
      this.resetClock();
    },
    async resetClock() {
      clearInterval(this.timer);
      clearInterval(this.retry);
      const date = new Date();
      try {
        await clock("reset", this.code, {
          date: date,
          seconds: 0,
          part: this.part,
          event: this.code,
        });
      } catch (error) {
        console.error(error);
        this.retry = setInterval(async () => {
          try {
            await clock("reset", this.code, {
              date: date,
              seconds: 0,
              part: this.part,
              event: this.code,
            });
            clearInterval(this.retry);
          } catch (error) {
            console.error(error);
          }
        }, 1000);
      }
      if (this.increment === -1)
        this.seconds = this.part >= 5 ? this.overtime : this.duration;
      else
        this.seconds =
          this.part % 2 === 0
            ? this.part === 6
              ? this.overtime
              : this.duration
            : 0;
      this.timer = null;
    },
    updatePart(part) {
      this.modal.status = false;
      this.part = part;
      localStorage.part = part;
      this.resetClock();
    },
    editTimer() {
      this.timerStatus = true;
    },
    closeEditTimer() {
      this.timerStatus = false;
      const minutes = parseInt(this.editedTimer.split(":")[0], 10);
      const seconds = parseInt(this.editedTimer.split(":")[1], 10);
      if (!isNaN(minutes) && !isNaN(seconds)) {
        this.seconds = minutes * 60 + seconds;
      }
      this.editedTimer = "";
    },
    closeEvent() {
      this.modal = {
        status: true,
        type: "confirm-action",
        action: "close-event",
      };
    },
    changePart() {
      this.modal = { status: true, type: "part-selection" };
    },
    async closeEventAction() {
      try {
        this.closed = true;
        this.modal.status = false;
        this.clock = false;
        this.resetClock();
        await update(
          "spotted_events",
          this.code,
          this.code,
          {
            status: -1,
          },
          60
        );
      } catch (error) {
        console.error(error);
      }
    },
    returnToCode() {
      this.modal = {
        status: true,
        type: "confirm-action",
        action: "go-code",
      };
    },
    goCode() {
      localStorage.timer = "false";
      this.pauseClock();
      this.$router.push("code");
    },
    executeOnConfirm(action) {
      if (action === "reset-events") this.resetAll();
      else if (action === "close-event") this.closeEventAction();
      else if (action === "go-code") this.goCode();
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/views/spotting";
</style>
