const axios = require("axios");
const axiosRetry = require("axios-retry");

axiosRetry(axios, {
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
});

function headers(token, retries) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    "axios-retry": {
      retries: retries || 0,
    },
  };
}

async function statsModelsOfUser(token, user, retries) {
  const response = await axios.get(
    `${process.env.VUE_APP_SPOTEASY_API_URL}/stats_models/user/${user}`,
    headers(token, retries)
  );
  if (response.data.status === "Failure") {
    throw new Error(response.message);
  }
  return response.data.array;
}

async function statsOfStatsModel(token, key, retries) {
  const response = await axios.get(
    `${process.env.VUE_APP_SPOTEASY_API_URL}/stats/stats_model/${key}`,
    headers(token, retries)
  );
  if (response.data.status === "Failure") {
    throw new Error(response.message);
  }
  return response.data.array;
}

async function valuesOfEvent(token, key, retries) {
  const response = await axios.get(
    `${process.env.VUE_APP_SPOTEASY_API_URL}/stat_values/spotted_event/${key}`,
    headers(token, retries)
  );
  if (response.data.status === "Failure") {
    throw new Error(response.message);
  }
  return response.data.array;
}

async function valueOfStatInEvent(token, key, code, retries) {
  const response = await axios.get(
    `${process.env.VUE_APP_SPOTEASY_API_URL}/stat_values/stat/${key}/spotted_event/${code}`,
    headers(token, retries)
  );
  if (response.data.status === "Failure") {
    throw new Error(response.message);
  }
  return response.data.object;
}

module.exports = {
  statsModelsOfUser,
  statsOfStatsModel,
  valuesOfEvent,
  valueOfStatInEvent,
};
