<template>
  <div id="model">
    <span id="name">
      <label class="margin-right">{{ $t("modal.model-name") }}</label>
      <input
        type="text"
        id="model_name"
        v-model="model_name"
        :placeholder="$t('modal.model-name')"
      />
      <label v-if="model_name_error" class="error" style="margin-left: 10px">
        {{ $t("admin.name-error") }}
      </label>
    </span>
    <span id="timer">
      <label for="toggle_timer">{{ $t("admin.timer") }}</label>
      <input
        type="checkbox"
        id="toggle_timer"
        class="margin-right"
        value="true"
        v-model="timer_checked"
        @click="timerCheck()"
      />
      <select v-if="timer_checked" v-model="chrono">
        <option v-for="model in chrono_models" :key="model.name" :value="model">
          {{
            `${model.name} (${
              model.part === 2 ? $t("modal.halftime") : $t("modal.quarter")
            },  ${Math.floor(model.regulation_time)} min${
              model.regulation_time % 1 !== 0
                ? ` ${(model.regulation_time % 1) * 60}`
                : ""
            }, ${
              model.increasing ? $t("modal.increasing") : $t("modal.decreasing")
            })`
          }}
        </option>
      </select>
    </span>
    <span id="entry">
      <input
        id="key-name"
        class="margin-right"
        type="text"
        v-model="new_entry.stat_key"
        :placeholder="$t('modal.key')"
      />
      <div class="margin-right">
        <input
          type="text"
          class="top-input"
          v-model="new_entry.singular_en"
          :placeholder="$t('modal.singular-en')"
        />
        <input
          type="text"
          v-model="new_entry.plural_en"
          :placeholder="$t('modal.plural-en')"
        />
      </div>
      <div class="margin-right">
        <input
          type="text"
          class="top-input"
          v-model="new_entry.singular_loc"
          :placeholder="$t('modal.singular-loc')"
        />
        <input
          type="text"
          v-model="new_entry.plural_loc"
          :placeholder="$t('modal.plural-loc')"
        />
      </div>
      <button class="n-btn-color-primary" id="add-entry" @click="addEntry()">
        <span class="material-icons">add</span>
      </button>
    </span>
    <span>
      <label v-if="entry_error" class="error">
        {{ $t("modal.stat-error") }}
      </label>
    </span>
    <div id="list" v-if="entries.length > 0">
      <table>
        <thead>
          <tr>
            <td colspan="2">{{ $t("modal.key") }}</td>
            <td colspan="2">{{ $t("modal.singular-en") }}</td>
            <td colspan="2">{{ $t("modal.plural-en") }}</td>
            <td colspan="2">{{ $t("modal.singular-loc") }}</td>
            <td colspan="2">{{ $t("modal.plural-loc") }}</td>
            <td colspan="1"></td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="entry in entries" :key="entries.indexOf(entry)">
            <td colspan="2">
              {{ entry.stat_key }}
            </td>
            <td colspan="2">
              {{ entry.singular_en }}
            </td>
            <td colspan="2">
              {{ entry.plural_en }}
            </td>
            <td colspan="2">
              {{ entry.singular_loc === "" ? "-" : entry.singular_loc }}
            </td>
            <td colspan="2">
              {{ entry.plural_loc === "" ? "-" : entry.plural_loc }}
            </td>
            <td colspan="1">
              <button
                class="n-btn-color-danger"
                @click="deleteEntry(entries.indexOf(entry))"
              >
                <span class="material-icons">delete</span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <span id="controls">
      <button
        class="n-btn-color-success"
        @click="modify === null ? confirm() : update()"
      >
        <span class="label">
          <span class="material-icons">check</span>
          {{ modify === null ? $t("modal.confirm") : $t("modal.update") }}
        </span>
      </button>
      <button
        class="n-btn-color-danger"
        v-if="modify !== null"
        @click="$emit('delete', { type: 'model', modified: modify })"
      >
        <span class="label">
          <span class="material-icons">delete</span> {{ $t("modal.delete") }}
        </span>
      </button>
      <button class="n-btn-color-primary" @click="$emit('close')">
        <span class="label">
          <span class="material-icons">close</span> {{ $t("modal.close") }}
        </span>
      </button>
    </span>
  </div>
</template>

<script>
import chrono_models from "@/assets/chrono_models.json";

export default {
  name: "ModelConfig",
  props: ["modify", "type", "stats_models"],
  data() {
    return {
      datetime:
        this.modify === null
          ? new Date().toISOString().slice(0, -8)
          : this.modify.datetime === undefined
          ? undefined
          : this.modify.datetime.slice(0, -8),
      home: this.modify === null ? "Home" : this.modify.home,
      away: this.modify === null ? "Away" : this.modify.away,
      stats_model:
        this.modify === null ? this.stats_models[0] : this.modify.stats_model,
      chrono_models: chrono_models,
      timer_checked: this.modify === null ? false : this.modify.chrono !== null,
      model_name: this.modify === null ? "" : this.modify.name,
      chrono: this.modify === null ? chrono_models[0] : this.modify.chrono,
      new_entry: {
        singular_en: "",
        plural_en: "",
        singular_loc: "",
        plural_loc: "",
        stat_key: "",
      },
      new_stats: [],
      old_stats: [],
      entries:
        this.modify === null
          ? []
          : this.modify.entries === undefined
          ? undefined
          : this.modify.entries.slice(),
      model_name_error: false,
      entry_error: false,
    };
  },
  computed: {
    eventName() {
      return `${this.home} vs. ${this.away}`;
    },
  },
  methods: {
    addEntry() {
      if (
        this.new_entry.stat_key !== "" &&
        this.checkEntry(this.new_entry.stat_key) &&
        this.new_entry.singular_en !== "" &&
        this.new_entry.plural_en !== ""
      ) {
        this.new_stats.push(Object.assign({}, this.new_entry));
        this.entries.push(Object.assign({}, this.new_entry));
        this.entry_error = false;
        this.new_entry.stat_key = "";
        this.new_entry.plural_en = "";
        this.new_entry.plural_loc = "";
        this.new_entry.singular_en = "";
        this.new_entry.singular_loc = "";
      } else {
        this.entry_error = true;
      }
    },
    checkEntry(stat_key) {
      let result = true;
      this.new_stats.forEach((element) => {
        if (element.stat_key === stat_key) {
          result = false;
        }
      });
      return result;
    },
    deleteEntry(index) {
      this.old_stats.push(this.entries[index]);
      const deleted = this.entries.splice(index, 1);
      this.new_stats.splice(this.new_stats.indexOf(deleted), 1);
    },
    confirm() {
      if (this.model_name !== "") {
        this.$emit("confirm", {
          name: this.model_name,
          entries: this.new_stats,
          chrono: this.timer_checked ? this.chrono : null,
        });
      } else {
        this.model_name_error = true;
      }
    },
    update() {
      if (this.model_name !== "") {
        this.$emit("update", {
          id: this.modify.id,
          name:
            this.model_name !== this.modify.name ? this.model_name : undefined,
          new_stats: this.new_stats.length !== 0 ? this.new_stats : undefined,
          old_stats: this.old_stats.length !== 0 ? this.old_stats : undefined,
          chrono: this.timer_checked
            ? this.chrono !== this.modify.chrono
              ? this.chrono
              : undefined
            : null,
        });
      } else {
        this.model_name_error = true;
      }
    },
    findIndex(key, array, value) {
      let i;
      for (i = 0; i < array.length; i++) {
        const element = array[i];
        if (element[key] === value) break;
      }
      return i === array.length ? -1 : i;
    },
    timerCheck() {
      if (!this.timer_checked && this.chrono === null) {
        this.chrono = this.chrono_models[0];
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/components/model";
</style>
