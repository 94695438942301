<template>
  <div id="code">
    <div class="cardback">
      <div class="cardfront">
        <h1 class="font">SPOTEASY</h1>
      </div>
    </div>
    <div class="cardback">
      <div id="form" class="cardfront">
        <h2 class="font">{{ $t("code.event") }}</h2>
        <input type="text" v-model="input_code" />
        <span id="confirm">
          <button class="n-btn-color-primary" @click="sendCode">
            <span class="material-icons">done</span>
          </button>
          <h3 v-if="error">{{ $t("code.code-error") }}</h3>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { read } from "@/api/crud";

export default {
  name: "Login",
  data() {
    return {
      input_code: null,
      error: false,
    };
  },
  mounted() {
    localStorage.code = "undefined";
    localStorage.events = "undefined";
    localStorage.seconds = "undefined";
    localStorage.timer = "undefined";
    localStorage.part = "undefined";
  },
  methods: {
    async sendCode() {
      try {
        await read(
          "spotted_events",
          this.input_code.toUpperCase(),
          this.input_code.toUpperCase()
        );
        localStorage.code = this.input_code.toUpperCase();
        this.$router.push({
          name: "Spotting",
        });
      } catch (error) {
        console.error(error);
        this.error = true;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/views/code";
</style>
