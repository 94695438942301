const axios = require("axios");
const axiosRetry = require("axios-retry");

axiosRetry(axios, {
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
});

function headers(token, retries) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    "axios-retry": {
      retries: retries || 0,
    },
  };
}

async function create(route, token, data, retries) {
  const response = await axios.post(
    `${process.env.VUE_APP_SPOTEASY_API_URL}/${route}`,
    data,
    headers(token, retries)
  );
  if (response.data.status === "Failure") {
    throw new Error(response.message);
  }
  return response.data.created;
}

async function read(route, token, key, retries) {
  const response = await axios.get(
    `${process.env.VUE_APP_SPOTEASY_API_URL}/${route}/${key}`,
    headers(token, retries)
  );
  if (response.data.status === "Failure") {
    throw new Error(response.message);
  }
  return response.data.object;
}

async function list(route, token, retries) {
  const response = await axios.get(
    `${process.env.VUE_APP_SPOTEASY_API_URL}/${route}`,
    headers(token, retries)
  );
  if (response.data.status === "Failure") {
    throw new Error(response.message);
  }
  return response.data.array;
}

async function update(route, token, key, data, retries) {
  const response = await axios.put(
    `${process.env.VUE_APP_SPOTEASY_API_URL}/${route}/${key}`,
    data,
    headers(token, retries)
  );
  if (response.data.status === "Failure") {
    throw new Error(response.message);
  }
  return response.data.updated;
}

async function drop(route, token, key, retries) {
  const response = await axios.delete(
    `${process.env.VUE_APP_SPOTEASY_API_URL}/${route}/${key}`,
    headers(token, retries)
  );
  if (response.data.status === "Failure") {
    throw new Error(response.message);
  }
  return response.data.deleted;
}

module.exports = {
  create,
  read,
  list,
  update,
  drop,
};
