<template>
  <div id="admin">
    <div id="stats_models" class="cardback">
      <div class="cardfront column">
        <button class="n-btn-color-primary header" @click="openModal('model')">
          <span>
            <span class="material-icons">add</span>
            {{ $t("admin.new-model") }}
          </span>
        </button>
        <ul>
          <li v-for="model in stats_models" :key="model.id">
            <button
              id="model_button"
              class="n-btn-color-primary"
              @click="modifyModal(model, 'model')"
            >
              {{
                model.name.length > 13
                  ? `${model.name.slice(0, 13)}...`
                  : model.name
              }}
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div id="spotted_events" class="cardback">
      <div class="cardfront column">
        <span id="event-header">
          <input
            type="text"
            :placeholder="$t('admin.home')"
            class="margin-right text"
            v-model="home"
            :disabled="stats_models.length < 1"
          />
          <input
            type="text"
            :placeholder="$t('admin.away')"
            class="margin-right text"
            v-model="away"
            :disabled="stats_models.length < 1"
          />
          <input
            id="datetime"
            type="datetime-local"
            :min="new Date().toISOString().slice(0, -8)"
            class="margin-right"
            v-model="datetime"
            :disabled="stats_models.length < 1"
          />
          <select
            class="margin-right"
            v-model="stats_model"
            :disabled="stats_models.length < 1"
          >
            <option
              v-for="model in stats_models"
              :key="model.id"
              :value="model"
            >
              {{
                `${
                  model.name.length > 15
                    ? `${model.name.slice(0, 12)}...`
                    : model.name
                }`
              }}
            </option>
          </select>
          <button
            class="margin-right"
            v-bind:class="{ 'n-btn-color-primary': stats_models.length > 0 }"
            :disabled="stats_models.length < 1"
            @click="
              home !== '' && away !== ''
                ? addNewElement({
                    home: home,
                    away: away,
                    stats_model: stats_model,
                    datetime: datetime + ':00.000Z',
                  })
                : (name_error = true)
            "
          >
            <span class="material-icons">add</span>
          </button>
          <label class="error" v-if="name_error">
            {{ $t("admin.name-error") }}
          </label>
        </span>
        <button
          id="sort"
          v-bind:class="{ 'n-btn-color-primary': spotted_events.length > 0 }"
          @click="sortBy()"
          :disabled="spotted_events.length === 0"
        >
          <span>
            <span class="material-icons">filter_list</span>
            {{
              `${$t("admin.sort")} ${sort === "datetime" ? "date" : "status"}`
            }}
          </span>
        </button>
        <ul>
          <li v-for="event in spotted_events" :key="event.id">
            <span id="event-span">
              <a @click="modifyModal(event, 'event')">
                {{
                  `${new Date(event.datetime)
                    .toLocaleString("en-GB", { timeZone: "UTC" })
                    .slice(0, -3)} - ${
                    event.home.length > 25
                      ? `${event.home.slice(0, 25)}...`
                      : event.home
                  } vs. ${
                    event.away.length > 25
                      ? `${event.away.slice(0, 25)}...`
                      : event.away
                  } - ${statusToString(event.status)}`
                }}
              </a>
              <h3>{{ event.code }}</h3>
              <button
                class="n-btn-color-warning"
                v-if="event.status !== -1"
                @click="closeEvent(event)"
              >
                <span class="label">
                  <span class="material-icons">event_busy</span>
                  {{ $t("spotting.close-event") }}
                </span>
              </button>
            </span>
          </li>
        </ul>
      </div>
    </div>
    <div id="extra" class="cardback">
      <div class="cardfront">
        <button class="n-btn-color-primary" @click="openModal('keylist')">
          <span
            ><span class="material-icons">list</span
            >{{ $t("admin.api-key") }}</span
          >
        </button>
        <button class="n-btn-color-danger" @click="logout">
          <span class="material-icons">logout</span>
        </button>
      </div>
    </div>
    <Modal v-if="modal.status === true" @close="modal.status = false">
      <template>
        <Model
          v-if="modal.type === 'model'"
          @confirm="addNewElement"
          @update="updateElement"
          @delete="deleteElement"
          @close="modal.status = false"
          :type="modal.type"
          :modify="modify"
          :stats_models="stats_models"
        />
        <Event
          v-if="modal.type === 'event'"
          @confirm="addNewElement"
          @update="updateElement"
          @delete="deleteElement"
          @close="modal.status = false"
          :type="modal.type"
          :modify="modify"
          :stats_models="stats_models"
          :token="token"
        />
        <KeyList
          v-if="modal.type === 'keylist'"
          @close="modal.status = false"
          :token="token"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import api_crud from "@/api/crud";
import {
  statsOfStatsModel,
  valuesOfEvent,
  statsModelsOfUser,
} from "@/api/specific";

import Modal from "@/components/Modal";
import Model from "@/components/Model";
import Event from "@/components/Event";
import KeyList from "@/components/KeyList";

export default {
  name: "Home",
  components: {
    Modal,
    Model,
    Event,
    KeyList,
  },
  data() {
    return {
      sort: "date",
      stats_models: [],
      spotted_events: [],
      modal: {
        status: false,
        type: "part-selection",
      },
      modify: null,
      token: null,
      name_error: false,
      home: "",
      away: "",
      datetime: new Date().toISOString().slice(0, -8),
      stats_model: null,
    };
  },
  async mounted() {
    try {
      this.token = await this.$auth.getTokenSilently();
      const stats_models = await statsModelsOfUser(
        this.token,
        `${this.$auth.user.sub.slice(6)}@${this.$auth.user.email.split("@")[1]}`
      );
      for (const stats_model of stats_models) {
        const stats = await statsOfStatsModel(this.token, stats_model.id);
        this.stats_models.push({
          ...stats_model,
          entries: stats,
        });
      }
      this.stats_model = this.stats_models[0];
      const spotted_events = await api_crud.list("spotted_events", this.token);
      for (const spotted_event of spotted_events) {
        const stats_model = this.stats_models[
          this.findIndex("id", this.stats_models, spotted_event.fk_stats_model)
        ];
        if (stats_model) {
          this.spotted_events.unshift({
            ...spotted_event,
            stats_model: stats_model,
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    openModal(type) {
      this.modify = null;
      this.modal = {
        status: true,
        type: type,
      };
    },
    async addNewElement(payload) {
      try {
        if (payload.name) {
          const stats_model = await api_crud.create(
            "stats_models",
            this.token,
            {
              ...payload,
              user: `${this.$auth.user.sub.slice(6)}@${
                this.$auth.user.email.split("@")[1]
              }`,
            }
          );
          let stats = [];
          payload.entries.forEach(async (stat) => {
            const stat_created = await api_crud.create("stats", this.token, {
              ...stat,
              fk_stats_model: stats_model.id,
            });
            stats.push(stat_created);
          });
          this.stats_models.push({ ...stats_model, entries: stats });
          this.stats_model = { ...stats_model, entries: stats };
        } else if (payload.stats_model) {
          const spotted_event = await api_crud.create(
            "spotted_events",
            this.token,
            {
              ...payload,
              fk_stats_model: payload.stats_model.id,
            }
          );
          this.spotted_events.unshift({
            ...spotted_event,
            stats_model: payload.stats_model,
          });
          this.home = "";
          this.away = "";
          this.name_error = false;
          payload.stats_model.entries.forEach(async (stat) => {
            await api_crud.create("stat_values", this.token, {
              fk_stat: stat.id,
              fk_spotted_event: spotted_event.code,
            });
          });
        }
        this.modal.status = false;
      } catch (error) {
        console.error(error);
      }
    },
    async updateElement(payload) {
      try {
        if (payload.id) {
          if (payload.name || payload.chrono !== undefined) {
            await api_crud.update(
              "stats_models",
              this.token,
              payload.id,
              payload
            );
            if (payload.name)
              this.stats_models[
                this.findIndex("id", this.stats_models, payload.id)
              ].name = payload.name;
            if (payload.chrono !== undefined)
              this.stats_models[
                this.findIndex("id", this.stats_models, payload.id)
              ].chrono = payload.chrono;
          }
          if (payload.new_stats) {
            payload.new_stats.forEach(async (stat) => {
              if (
                payload.old_stats === undefined ||
                this.findIndex("stat_key", payload.old_stats, stat.stat_key) ===
                  -1
              ) {
                const created = await api_crud.create("stats", this.token, {
                  ...stat,
                  fk_stats_model: payload.id,
                });
                this.stats_models[
                  this.findIndex("id", this.stats_models, payload.id)
                ].entries.push(created);
                const stat_model = this.stats_models[
                  this.findIndex("id", this.stats_models, payload.id)
                ];
                this.spotted_events.forEach(async (event) => {
                  if (event.fk_stats_model === stat_model.id) {
                    await api_crud.create("stat_values", this.token, {
                      fk_stat: created.id,
                      fk_spotted_event: event.code,
                    });
                  }
                });
              }
            });
          }
          if (payload.old_stats) {
            payload.old_stats.forEach(async (stat) => {
              if (
                payload.new_stats === undefined ||
                this.findIndex("stat_key", payload.new_stats, stat.stat_key) ===
                  -1
              ) {
                await api_crud.drop("stats", this.token, stat.id);
                const stats = this.stats_models[
                  this.findIndex("id", this.stats_models, payload.id)
                ].entries;
                stats.splice(this.findIndex("id", stats, stat.id), 1);
              }
            });
          }
        } else if (payload.code) {
          if (payload.home || payload.away || payload.datetime) {
            await api_crud.update(
              "spotted_events",
              this.token,
              payload.code,
              payload
            );
            if (payload.home)
              this.spotted_events[
                this.findIndex("code", this.spotted_events, payload.code)
              ].home = payload.home;
            if (payload.away)
              this.spotted_events[
                this.findIndex("code", this.spotted_events, payload.code)
              ].away = payload.away;
            if (payload.datetime)
              this.spotted_events[
                this.findIndex("code", this.spotted_events, payload.code)
              ].datetime = payload.datetime;
          }
          if (payload.stats_model) {
            const values = await valuesOfEvent(this.token, payload.code);
            values.forEach(async (stat_value) => {
              await api_crud.drop("stat_values", this.token, stat_value.id);
            });
            payload.stats_model.entries.forEach(async (stat) => {
              await api_crud.create("stat_values", this.token, {
                fk_stat: stat.id,
                fk_spotted_event: payload.code,
              });
            });
            await api_crud.update("spotted_events", this.token, payload.code, {
              fk_stats_model: payload.stats_model.id,
            });
            this.spotted_events[
              this.findIndex("code", this.spotted_events, payload.code)
            ].stats_model = payload.stats_model;
          }
        }
        this.modal.status = false;
      } catch (error) {
        console.error(error);
      }
    },
    async deleteElement(payload) {
      try {
        if (payload.type === "model") {
          const model = this.stats_models.indexOf(payload.modified);
          await api_crud.drop("stats_models", this.token, payload.modified.id);
          const events = [...this.spotted_events];
          events.forEach(async (event) => {
            if (event.fk_stats_model === payload.modified.id) {
              this.spotted_events.splice(this.spotted_events.indexOf(event), 1);
            }
          });
          this.stats_models.splice(model, 1);
          this.modal.status = false;
        } else if (payload.type === "event") {
          await api_crud.drop(
            "spotted_events",
            this.token,
            payload.modified.code
          );
          this.spotted_events.splice(
            this.spotted_events.indexOf(payload.modified),
            1
          );
          this.modal.status = false;
        }
      } catch (error) {
        console.error(error);
      }
    },
    modifyModal(element, type) {
      this.modify = element;
      this.modal = {
        status: true,
        type: type,
      };
    },
    statusToString(status) {
      switch (status) {
        case 0:
          return this.$t("admin.scheduled");
        case 1:
          return this.$t("admin.progress");
        case -1:
          return this.$t("admin.closed");
        default:
          return "Unknown";
      }
    },
    sortBy() {
      this.spotted_events.sort((a, b) => {
        const e1 = a[this.sort];
        const e2 = b[this.sort];
        if (e1 > e2) return 1;
        else if (e1 === e2) return 0;
        else return -1;
      });
      this.sort = this.sort === "datetime" ? "status" : "datetime";
    },
    async closeEvent(event) {
      try {
        await api_crud.update("spotted_events", this.token, event.code, {
          status: -1,
        });
        event.status = -1;
      } catch (error) {
        console.error(error);
      }
    },
    findIndex(key, array, value) {
      let i;
      for (i = 0; i < array.length; i++) {
        const element = array[i];
        if (element[key] === value) break;
      }
      return i === array.length ? -1 : i;
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/views/admin";
</style>
