const axios = require("axios");

function headers(token) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

async function clock(action, code, data) {
  const response = await axios.post(
    `${process.env.VUE_APP_SPOTEASY_API_URL}/clock/${code}/${action}/`,
    data,
    headers(code)
  );
  if (response.data.status === "Failure") {
    throw new Error(response.message);
  }
  return response.data.status;
}

module.exports = {
  clock,
};
