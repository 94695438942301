<template>
  <div id="entries">
    <table>
      <thead>
        <tr>
          <td id="home-name" colspan="1">
            {{ home.length > 25 ? `${home.slice(0, 22)}...` : home }}
          </td>
          <td colspan="1"></td>
          <td id="away-name" colspan="1">
            {{ away.length > 25 ? `${away.slice(0, 22)}...` : away }}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="stat in entries" :key="stat.id">
          <td colspan="1">
            <h2 v-if="closed">{{ stat.home }}</h2>
            <button
              class="n-btn-color-primary"
              @click="increment(stat, 'home')"
              v-else
            >
              {{ stat.home }}
            </button>
          </td>
          <td colspan="1">
            {{ stat.plural_en }}
          </td>
          <td colspan="1">
            <h2 v-if="closed">{{ stat.away }}</h2>
            <button
              class="n-btn-color-primary"
              @click="increment(stat, 'away')"
              v-else
            >
              {{ stat.away }}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { update } from "@/api/crud";

export default {
  name: "Statistics",
  props: ["entries", "closed", "home", "away", "code"],
  data() {
    return {
      mode: 1,
    };
  },
  methods: {
    async increment(stat, side) {
      side === "home" ? (stat.home += 1) : (stat.away += 1);
      await update(
        "stat_values",
        this.code,
        stat.stat_values,
        side === "home"
          ? {
              home: stat.home,
              stat_key: stat.stat_key,
              event: this.code,
            }
          : {
              away: stat.away,
              stat_key: stat.stat_key,
              event: this.code,
            },
        10
      );
      this.$emit("change-value", { stat: stat, side: side });
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/components/statistics";
</style>
