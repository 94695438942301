<template>
  <div id="home" class="cardback">
    <div>
      <h1>{{ $t("home.welcome") }}</h1>
      <span>
        <router-link to="/admin">{{ $t("home.admin-ui") }}</router-link> |
        <router-link to="/spotting">{{ $t("home.spotting-ui") }}</router-link>
      </span>
      <select v-model="language" @change="onChange()">
        <option value="en">English</option>
        <option value="fr">Français</option>
      </select>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      language: localStorage.language || "en",
    };
  },
  methods: {
    onChange() {
      localStorage.language = this.language;
      this.$router.go();
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/views/home";
</style>
