<template>
  <div id="modal" @click.self="close">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Modal",
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/components/modal";
</style>
