<template>
  <div id="events">
    <table>
      <tbody>
        <tr
          v-for="event in events"
          :key="event.id"
          v-bind:class="{
            'increase-event': event.running === undefined,
            'timer-event': event.running !== undefined,
          }"
        >
          <td v-if="clock" class="align-center" colspan="1">
            {{ time(event.timestamp) }}
          </td>
          <td v-if="event.stat !== undefined" class="align-center" colspan="2">
            {{
              `+1 ${event.stat.singular_en} ${$t("spotting.event-format")} ${
                event.side.length > 25
                  ? `${event.side.slice(0, 22)}...`
                  : event.side
              }`
            }}
          </td>
          <!-- <td v-else class="align-center" colspan="2">
            {{ `${event.running ? "Timer resumed" : "Timer paused"}` }}
          </td> -->
          <td class="align-center" colspan="1" v-if="!closed">
            <button
              v-if="event.stat !== undefined"
              class="n-btn-color-danger"
              @click="removeEvent(event)"
            >
              <span class="material-icons">delete</span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <span id="buttons">
      <button
        class="n-btn-color-warning button"
        v-if="!closed"
        @click="
          $emit('reset-all', {
            status: true,
            type: 'confirm-action',
            action: 'reset-events',
          })
        "
      >
        <span class="label">
          <span class="material-icons">replay</span>
          {{ $t("spotting.reset-all") }}
        </span>
      </button>
      <button
        class="n-btn-color-warning button"
        v-if="!closed"
        @click="
          $emit('reset-all', {
            status: true,
            type: 'confirm-action',
            action: 'close-event',
          })
        "
      >
        <span class="label">
          <span class="material-icons">event_busy</span>
          {{ $t("spotting.close-event") }}
        </span>
      </button>
    </span>
  </div>
</template>

<script>
export default {
  name: "EventList",
  props: ["events", "closed", "clock"],
  methods: {
    removeEvent(event) {
      this.$emit("delete-event", { event: event });
    },
    time(timestamp) {
      const minutes = timestamp !== 0 ? Math.floor(timestamp / 60) : 0;
      const seconds = timestamp % 60;
      return timestamp >= 0
        ? `${minutes < 10 ? `0${minutes}` : `${minutes}`}:${
            seconds < 10 ? `0${seconds}` : `${seconds}`
          }`
        : `${
            -1 * minutes < 10 ? `0${-1 * minutes - 1}` : `${-1 * minutes - 1}`
          }:${-1 * seconds < 10 ? `0${-1 * seconds}` : `${-1 * seconds}`}`;
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/components/eventlist";
</style>
