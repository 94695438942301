var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"events"}},[_c('table',[_c('tbody',_vm._l((_vm.events),function(event){return _c('tr',{key:event.id,class:{
          'increase-event': event.running === undefined,
          'timer-event': event.running !== undefined,
        }},[(_vm.clock)?_c('td',{staticClass:"align-center",attrs:{"colspan":"1"}},[_vm._v(" "+_vm._s(_vm.time(event.timestamp))+" ")]):_vm._e(),(event.stat !== undefined)?_c('td',{staticClass:"align-center",attrs:{"colspan":"2"}},[_vm._v(" "+_vm._s(("+1 " + (event.stat.singular_en) + " " + (_vm.$t("spotting.event-format")) + " " + (event.side.length > 25 ? ((event.side.slice(0, 22)) + "...") : event.side)))+" ")]):_vm._e(),(!_vm.closed)?_c('td',{staticClass:"align-center",attrs:{"colspan":"1"}},[(event.stat !== undefined)?_c('button',{staticClass:"n-btn-color-danger",on:{"click":function($event){return _vm.removeEvent(event)}}},[_c('span',{staticClass:"material-icons"},[_vm._v("delete")])]):_vm._e()]):_vm._e()])}),0)]),_c('span',{attrs:{"id":"buttons"}},[(!_vm.closed)?_c('button',{staticClass:"n-btn-color-warning button",on:{"click":function($event){return _vm.$emit('reset-all', {
          status: true,
          type: 'confirm-action',
          action: 'reset-events',
        })}}},[_c('span',{staticClass:"label"},[_c('span',{staticClass:"material-icons"},[_vm._v("replay")]),_vm._v(" "+_vm._s(_vm.$t("spotting.reset-all"))+" ")])]):_vm._e(),(!_vm.closed)?_c('button',{staticClass:"n-btn-color-warning button",on:{"click":function($event){return _vm.$emit('reset-all', {
          status: true,
          type: 'confirm-action',
          action: 'close-event',
        })}}},[_c('span',{staticClass:"label"},[_c('span',{staticClass:"material-icons"},[_vm._v("event_busy")]),_vm._v(" "+_vm._s(_vm.$t("spotting.close-event"))+" ")])]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }