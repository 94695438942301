<template>
  <div id="superadmin" class="cardback">
    <h1>Superadmin</h1>
    <div id="users" class="cardback" v-if="mounted">
      <div class="cardfront" v-for="user in users" :key="user">
        <h2 v-if="stats_models[user] && stats_models[user].length > 0">
          {{ `${$t("admin.user")}: ` }}
          <b>{{ user ? `${user}` : "no-user" }}</b>
        </h2>
        <ul v-if="stats_models[user] && stats_models[user].length > 0">
          <li v-for="stats_model in stats_models[user]" :key="stats_model.id">
            <hr v-if="stats_models[user].indexOf(stats_model) !== 0" />
            <div id="model-global">
              <div id="model-info" @click="openModel(stats_model)">
                <h3>
                  {{ `${$t("modal.model-name")}: ` }}
                  <b>{{ `${stats_model.name}` }}</b>
                </h3>
                <h3 v-if="stats_model.chrono">
                  {{ `${$t("admin.timer")}: ` }}
                  <b>{{ `${stats_model.chrono.name}` }}</b>
                </h3>
                <h3>
                  {{
                    stats_model.entries.length !== 0
                      ? `${$t("admin.with-stat")}:`
                      : $t("admin.no-stat")
                  }}
                </h3>
                <table v-if="stats_model.entries.length !== 0">
                  <thead>
                    <tr>
                      <td colspan="1">{{ $t("modal.key") }}</td>
                      <td colspan="1">{{ $t("modal.singular-en") }}</td>
                      <td colspan="1">{{ $t("modal.plural-en") }}</td>
                      <td colspan="1">{{ $t("modal.singular-loc") }}</td>
                      <td colspan="1">{{ $t("modal.plural-loc") }}</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="stat in stats_model.entries"
                      :key="stats_model.entries.indexOf(stat)"
                    >
                      <td colspan="1">
                        {{ stat.stat_key }}
                      </td>
                      <td colspan="1">
                        {{ stat.singular_en }}
                      </td>
                      <td colspan="1">
                        {{ stat.plural_en }}
                      </td>
                      <td colspan="1">
                        {{ stat.singular_loc === "" ? "-" : stat.singular_loc }}
                      </td>
                      <td colspan="1">
                        {{ stat.plural_loc === "" ? "-" : stat.plural_loc }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div id="linked-events">
                <h3
                  v-if="
                    spotted_events[stats_model.id] &&
                    spotted_events[stats_model.id].length > 0
                  "
                >
                  {{ `${$t("admin.with-event")}:` }}
                </h3>
                <h3 v-else>{{ $t("admin.no-event") }}</h3>
                <div
                  v-for="spotted_event in spotted_events[stats_model.id]"
                  :key="spotted_event.code"
                  @click="openEvent(spotted_event)"
                >
                  <h3>
                    {{ `${$t("modal.event-name")}: ` }}
                    <b>{{
                      `${spotted_event.home} vs. ${spotted_event.away}`
                    }}</b>
                  </h3>
                  <h3>
                    {{ `${$t("code.event")}: ` }}
                    <b>{{ `${spotted_event.code}` }}</b>
                  </h3>
                  <h3>
                    {{ `${$t("modal.datetime")}: ` }}
                    <b>{{
                      `${new Date(spotted_event.datetime)
                        .toLocaleString("fr-FR", { timeZone: "UTC" })
                        .slice(0, -3)}`
                    }}</b>
                  </h3>
                  <h3>
                    {{ `${$t("admin.status")}: ` }}
                    <b>{{ `${statusToString(spotted_event.status)}` }}</b>
                  </h3>
                  <table v-if="stats_model.entries.length !== 0">
                    <thead>
                      <tr>
                        <td colspan="1">{{ $t("modal.key") }}</td>
                        <td colspan="1">{{ $t("modal.singular-en") }}</td>
                        <td colspan="1">{{ $t("modal.plural-en") }}</td>
                        <td colspan="1">{{ spotted_event.home }}</td>
                        <td colspan="1">{{ spotted_event.away }}</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="stat in stats_model.entries"
                        :key="stats_model.entries.indexOf(stat)"
                      >
                        <td colspan="1">
                          {{ stat.stat_key }}
                        </td>
                        <td colspan="1">
                          {{ stat.singular_en }}
                        </td>
                        <td colspan="1">
                          {{ stat.plural_en }}
                        </td>
                        <td colspan="1">
                          {{
                            value(stat.id, spotted_event.stat_values, "home")
                          }}
                        </td>
                        <td colspan="1">
                          {{
                            value(stat.id, spotted_event.stat_values, "away")
                          }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <button
                    class="n-btn-color-warning"
                    v-if="spotted_event.status !== -1"
                    @click="closeEvent(spotted_event)"
                  >
                    <span class="label">
                      <span class="material-icons">event_busy</span>
                      {{ $t("spotting.close-event") }}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <Modal v-if="modal.status === true" @close="modal.status = false">
      <template>
        <Model
          v-if="modal.type === 'model'"
          @update="updateElement"
          @delete="deleteElement"
          @close="modal.status = false"
          :type="modal.type"
          :modify="modify"
          :stats_models="stats_models_array"
        />
        <Event
          v-if="modal.type === 'event'"
          @update="updateElement"
          @delete="deleteElement"
          @close="modal.status = false"
          :type="modal.type"
          :modify="modify"
          :stats_models="stats_models_array"
          :token="token"
        />
      </template>
    </Modal>
  </div>
</template>

<script>
import { list, create, update, drop } from "@/api/crud";
import { statsOfStatsModel, valuesOfEvent } from "@/api/specific";

import Modal from "@/components/Modal";
import Model from "@/components/Model";
import Event from "@/components/Event";

export default {
  name: "Superadmin",
  components: {
    Modal,
    Model,
    Event,
  },
  data() {
    return {
      users: [],
      stats_models: {},
      token: null,
      mounted: false,
      spotted_events: {},
      modal: {
        status: false,
        type: "part-selection",
      },
      modify: null,
      stats_models_array: [],
    };
  },
  async mounted() {
    try {
      this.token = await this.$auth.getTokenSilently();
      const result = await list("stats_models", this.token);
      for (const stats_model of result) {
        const stats = await statsOfStatsModel(this.token, stats_model.id);
        if (this.users.indexOf(stats_model.user) === -1) {
          this.users.push(stats_model.user);
          this.stats_models[stats_model.user] = [
            { ...stats_model, entries: stats },
          ];
        } else {
          this.stats_models[stats_model.user].push({
            ...stats_model,
            entries: stats,
          });
        }
        this.stats_models_array.push({
          ...stats_model,
          entries: stats,
        });
        this.stats_models[stats_model.user].sort((a, b) => a.id - b.id);
        this.stats_models_array.sort((a, b) => a.id - b.id);
      }
      const spotted_events = await list("spotted_events", this.token);
      const ids = [];
      for (const spotted_event of spotted_events) {
        const stat_values = await valuesOfEvent(this.token, spotted_event.code);
        if (ids.indexOf(spotted_event.fk_stats_model) === -1) {
          ids.push(spotted_event.fk_stats_model);
          this.spotted_events[spotted_event.fk_stats_model] = [
            {
              ...spotted_event,
              stat_values: stat_values,
              stats_model: this.findStatsModel(spotted_event.fk_stats_model),
            },
          ];
        } else {
          this.spotted_events[spotted_event.fk_stats_model].push({
            ...spotted_event,
            stat_values: stat_values,
            stats_model: this.findStatsModel(spotted_event.fk_stats_model),
          });
        }
        this.spotted_events[spotted_event.fk_stats_model].sort(
          (a, b) => a.code - b.code
        );
        this.mounted = true;
        this.users.sort();
      }
    } catch (error) {
      console.error(error);
    }
  },
  methods: {
    findStatsModel(key) {
      let found = null;
      this.users.forEach((user) => {
        this.stats_models[user].forEach((stats_model) => {
          if (stats_model.id === key) found = stats_model;
        });
      });
      return found;
    },
    findSpottedEvent(key) {
      let found = null;
      this.users.forEach((user) => {
        this.stats_models[user].forEach((stats_model) => {
          if (this.spotted_events[stats_model.id]) {
            this.spotted_events[stats_model.id].forEach((spotted_event) => {
              if (spotted_event.code === key) found = spotted_event;
            });
          }
        });
      });
      return found;
    },
    statusToString(status) {
      switch (status) {
        case 0:
          return this.$t("admin.scheduled");
        case 1:
          return this.$t("admin.progress");
        case -1:
          return this.$t("admin.closed");
        default:
          return "Unknown";
      }
    },
    value(id, values, side) {
      let value = 0;
      values.forEach((stat_value) => {
        if (stat_value.fk_stat === id) value = stat_value[side];
      });
      return value;
    },
    openModel(model) {
      this.modify = model;
      this.modal = {
        status: true,
        type: "model",
      };
    },
    openEvent(event) {
      this.modify = event;
      this.modal = {
        status: true,
        type: "event",
      };
    },
    async updateElement(payload) {
      try {
        if (payload.id) {
          if (payload.name || payload.chrono !== undefined) {
            await update("stats_models", this.token, payload.id, payload);
            if (payload.name) {
              this.findStatsModel(payload.id).name = payload.name;
            }
            if (payload.chrono !== undefined)
              this.findStatsModel(payload.id).chrono = payload.chrono;
          }
          if (payload.new_stats) {
            payload.new_stats.forEach(async (stat) => {
              if (
                payload.old_stats === undefined ||
                this.findIndex("stat_key", payload.old_stats, stat.stat_key) ===
                  -1
              ) {
                const created = await create("stats", this.token, {
                  ...stat,
                  fk_stats_model: payload.id,
                });
                this.findStatsModel(payload.id).entries.push(created);
                const stat_model = this.findStatsModel(payload.id);
                this.spotted_events[stat_model.id].forEach(async (event) => {
                  await create("stat_values", this.token, {
                    fk_stat: created.id,
                    fk_spotted_event: event.code,
                  });
                });
              }
            });
          }
          if (payload.old_stats) {
            payload.old_stats.forEach(async (stat) => {
              if (
                payload.new_stats === undefined ||
                this.findIndex("stat_key", payload.new_stats, stat.stat_key) ===
                  -1
              ) {
                await drop("stats", this.token, stat.id);
                const stats = this.findStatsModel(payload.id).entries;
                stats.splice(this.findIndex("id", stats, stat.id), 1);
              }
            });
          }
        } else if (payload.code) {
          if (payload.home || payload.away || payload.datetime) {
            await update("spotted_events", this.token, payload.code, payload);
            if (payload.home)
              this.findSpottedEvent(payload.code).home = payload.home;
            if (payload.away)
              this.findSpottedEvent(payload.code).away = payload.away;
            if (payload.datetime)
              this.findSpottedEvent(payload.code).datetime = payload.datetime;
          }
          if (payload.stats_model) {
            const values = await valuesOfEvent(this.token, payload.code);
            values.forEach(async (stat_value) => {
              await drop("stat_values", this.token, stat_value.id);
            });
            payload.stats_model.entries.forEach(async (stat) => {
              await create("stat_values", this.token, {
                fk_stat: stat.id,
                fk_spotted_event: payload.code,
              });
            });
            await update("spotted_events", this.token, payload.code, {
              fk_stats_model: payload.stats_model.id,
            });
            this.findSpottedEvent(payload.code).stats_model =
              payload.stats_model;
            this.$router.go();
          }
        }
        this.modal.status = false;
      } catch (error) {
        console.error(error);
      }
    },
    async deleteElement(payload) {
      try {
        if (payload.type === "model") {
          const model = this.findStatsModel(payload.modified.id);
          await drop("stats_models", this.token, payload.modified.id);
          const events = this.spotted_events[payload.modified.id];
          if (events) {
            events.forEach(async (event) => {
              if (event.fk_stats_model === payload.modified.id) {
                this.spotted_events[payload.modified.id].splice(
                  this.spotted_events[payload.modified.id].indexOf(event),
                  1
                );
              }
            });
          }
          this.users.forEach((user) => {
            if (this.stats_models[user].indexOf(model) !== -1) {
              this.stats_models[user].splice(
                this.stats_models[user].indexOf(model),
                1
              );
            }
          });
          this.stats_models_array.slice(model, 1);
          this.modal.status = false;
        } else if (payload.type === "event") {
          await drop("spotted_events", this.token, payload.modified.code);
          this.spotted_events[payload.modified.fk_stats_model].splice(
            this.spotted_events[payload.modified.fk_stats_model].indexOf(
              payload.modified
            ),
            1
          );
          this.modal.status = false;
        }
      } catch (error) {
        console.error(error);
      }
    },
    findIndex(key, array, value) {
      let i;
      for (i = 0; i < array.length; i++) {
        const element = array[i];
        if (element[key] === value) break;
      }
      return i === array.length ? -1 : i;
    },
    async closeEvent(event) {
      try {
        await update("spotted_events", this.token, event.code, {
          status: -1,
        });
        event.status = -1;
        this.modal.status = false;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/views/superadmin";
</style>
