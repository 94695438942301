<template>
  <div id="event">
    <span>
      <label class="margin-right">{{ $t("modal.event-name") }}</label>
      <label>{{ eventName }}</label>
    </span>
    <span id="teams-name">
      <div class="margin-right">
        <label>{{ $t("admin.home") }}</label>
        <input type="text" v-model="home" :placeholder="$t('admin.home')" />
      </div>
      <div>
        <label>{{ $t("admin.away") }}</label>
        <input type="text" v-model="away" :placeholder="$t('admin.away')" />
      </div>
      <label v-if="name_error" class="error" style="margin-left: 10px">
        {{ $t("admin.name-error") }}
      </label>
    </span>
    <span id="datetime">
      <label class="margin-right">{{ $t("modal.datetime") }}</label>
      <input
        type="datetime-local"
        v-model="datetime"
        :min="new Date().toISOString().slice(0, -8)"
      />
    </span>
    <span id="model-selection">
      <label class="margin-right">{{ $t("modal.selected") }}</label>
      <select v-model="stats_model">
        <option v-for="model in stats_models" :key="model.id" :value="model">
          {{
            `${
              model.name.length > 20
                ? `${model.name.slice(0, 20)}...`
                : model.name
            } (${model.timer ? `${model.chrono.name}, ` : ""}${
              model.entries.length
            } ${
              model.entries.length > 1 ? $t("modal.entries") : $t("modal.entry")
            })`
          }}
        </option>
      </select>
    </span>
    <div id="list" v-if="stats_model.entries.length > 0">
      <table>
        <thead>
          <tr>
            <td colspan="1">{{ $t("modal.key") }}</td>
            <td colspan="1">{{ $t("modal.singular-en") }}</td>
            <td colspan="1">{{ $t("modal.plural-en") }}</td>
            <td colspan="1">{{ modify.home }}</td>
            <td colspan="1">{{ modify.away }}</td>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="entry in stats_model.entries"
            :key="stats_model.entries.indexOf(entry)"
          >
            <td colspan="1">
              {{ entry.stat_key }}
            </td>
            <td colspan="1">
              {{ entry.singular_en }}
            </td>
            <td colspan="1">
              {{ entry.plural_en }}
            </td>
            <td colspan="1">
              {{ homeValue(entry.id) }}
            </td>
            <td colspan="1">
              {{ awayValue(entry.id) }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div style="flex-grow: 1" />
    <span id="controls">
      <button
        class="n-btn-color-success"
        @click="modify === null ? confirm() : update()"
        v-if="modify === null || modify.status === 0"
      >
        <span class="label">
          <span class="material-icons">check</span> {{ $t("modal.update") }}
        </span>
      </button>
      <button
        class="n-btn-color-danger"
        v-if="modify !== null"
        @click="$emit('delete', { type: 'event', modified: modify })"
      >
        <span class="label">
          <span class="material-icons">delete</span> {{ $t("modal.delete") }}
        </span>
      </button>
      <button class="n-btn-color-primary" @click="$emit('close')">
        <span class="label">
          <span class="material-icons">close</span> {{ $t("modal.close") }}
        </span>
      </button>
    </span>
  </div>
</template>

<script>
import { valuesOfEvent } from "@/api/specific";
import chrono_models from "@/assets/chrono_models.json";

export default {
  name: "Event",
  props: ["modify", "type", "stats_models", "token"],
  async mounted() {
    try {
      if (this.modify !== null && this.type === "event") {
        this.values = await valuesOfEvent(this.token, this.modify.code);
      }
    } catch (error) {
      console.error(error);
    }
  },
  data() {
    return {
      values: [],
      datetime:
        this.modify === null
          ? new Date().toISOString().slice(0, -8)
          : this.modify.datetime === undefined
          ? undefined
          : this.modify.datetime.slice(0, -8),
      home: this.modify === null ? "Home" : this.modify.home,
      away: this.modify === null ? "Away" : this.modify.away,
      stats_model:
        this.modify === null ? this.stats_models[0] : this.modify.stats_model,
      chrono_models: chrono_models,
      timer_checked: this.modify === null ? false : this.modify.chrono !== null,
      model_name: this.modify === null ? "" : this.modify.name,
      chrono: this.modify === null ? chrono_models[0] : this.modify.chrono,
      new_entry: {
        singular_en: "",
        plural_en: "",
        singular_loc: "",
        plural_loc: "",
        stat_key: "",
      },
      new_stats: [],
      old_stats: [],
      entries:
        this.modify === null
          ? []
          : this.modify.entries === undefined
          ? undefined
          : this.modify.entries.slice(),
      name_error: false,
      entry_error: false,
    };
  },
  computed: {
    eventName() {
      return `${this.home.slice(0, 25)} vs. ${this.away.slice(0, 25)}`;
    },
  },
  methods: {
    addEntry() {
      if (
        this.new_entry.stat_key !== "" &&
        this.checkEntry(this.new_entry.stat_key) &&
        this.new_entry.singular_en !== "" &&
        this.new_entry.plural_en !== ""
      ) {
        this.new_stats.push(Object.assign({}, this.new_entry));
        this.entries.push(Object.assign({}, this.new_entry));
        this.entry_error = false;
      } else {
        this.entry_error = true;
      }
    },
    checkEntry(stat_key) {
      let result = true;
      this.new_stats.forEach((element) => {
        if (element.stat_key === stat_key) {
          result = false;
        }
      });
      return result;
    },
    deleteEntry(index) {
      this.old_stats.push(this.entries[index]);
      this.entries.splice(index, 1);
    },
    confirm() {
      if (this.home !== "" && this.away !== "") {
        this.$emit("confirm", {
          home: this.home,
          away: this.away,
          stats_model: this.stats_model,
          datetime: this.datetime + ":00.000Z",
        });
      } else {
        this.name_error = true;
      }
    },
    update() {
      if (this.home !== "" && this.away !== "") {
        this.$emit("update", {
          home: this.home !== this.modify.home ? this.home : undefined,
          away: this.away !== this.modify.away ? this.away : undefined,
          stats_model:
            this.stats_model !== this.modify.stats_model
              ? this.stats_model
              : undefined,
          datetime:
            this.datetime + ":00.000Z" !== this.modify.datetime
              ? this.datetime + ":00.000Z"
              : undefined,
          code: this.modify.code,
        });
      } else {
        this.name_error = true;
      }
    },
    findIndex(key, array, value) {
      let i;
      for (i = 0; i < array.length; i++) {
        const element = array[i];
        if (element[key] === value) break;
      }
      return i === array.length ? -1 : i;
    },
    homeValue(id) {
      let value = 0;
      this.values.forEach((stat_value) => {
        if (stat_value.fk_stat === id) value = stat_value.home;
      });
      return value;
    },
    awayValue(id) {
      let value = 0;
      this.values.forEach((stat_value) => {
        if (stat_value.fk_stat === id) value = stat_value.away;
      });
      return value;
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/components/event";
</style>
