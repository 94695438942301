import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Admin from "../views/Admin.vue";
import Code from "../views/Code.vue";
import Spotting from "../views/Spotting.vue";
import Superadmin from "../views/Superadmin.vue";
import { authGuard } from "../auth/authGuard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    beforeEnter: authGuard,
  },
  {
    path: "/spotting",
    name: "Spotting",
    component: Spotting,
    beforeEnter: (to, from, next) => {
      if (localStorage.code !== "undefined") {
        next();
      } else {
        next({ name: "Code" });
      }
    },
  },
  {
    path: "/code",
    name: "Code",
    component: Code,
  },
  {
    path: "/SYSZfNgFJt",
    name: "Superadmin",
    component: Superadmin,
    beforeEnter: authGuard,
  },
  { path: "*", redirect: "/" },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
