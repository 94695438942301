<template>
  <div id="keylist">
    <span id="add-new">
      <input type="text" :placeholder="$t('modal.name')" v-model="name" />
      <button class="n-btn-color-primary" @click="add()">
        <span class="material-icons">add</span>
      </button>
    </span>
    <div id="list" v-if="keys.length > 0">
      <table>
        <thead>
          <tr>
            <td colspan="1">{{ $t("modal.name") }}</td>
            <td colspan="3">{{ $t("modal.token") }}</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="key in keys" :key="key.id">
            <td colspan="1">
              {{ key.name }}
            </td>
            <td colspan="3">
              {{ key.token }}
            </td>
            <td colspan="1">
              <button
                v-if="key.token.length !== 6"
                class="n-btn-color-danger"
                @click="remove(key)"
              >
                <span class="material-icons">delete</span>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <span id="controls">
      <button class="n-btn-color-primary" @click="$emit('close')">
        <span class="material-icons">close</span>
      </button>
    </span>
  </div>
</template>

<script>
import api_crud from "@/api/crud";

export default {
  name: "KeyList",
  props: ["token"],
  async mounted() {
    try {
      this.keys = await api_crud.list("public_tokens", this.token);
    } catch (error) {
      console.error(error);
    }
  },
  data() {
    return {
      name: "",
      keys: [],
    };
  },
  methods: {
    async add() {
      try {
        if (this.name !== "") {
          const created = await api_crud.create("public_tokens", this.token, {
            name: this.name,
          });
          this.keys.push(created);
          this.name = "";
        }
      } catch (error) {
        console.error(error);
      }
    },
    async remove(key) {
      try {
        await api_crud.drop("public_tokens", this.token, key.id);
        this.keys.splice(this.keys.indexOf(key), 1);
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/components/keylist";
</style>
